import { createRouter, createWebHistory } from 'vue-router'
const routerHistory = createWebHistory()
const routes=[
	//这里和vue2一样
	{
		path:'/facialMask',
		component:() => import('../page/publicize/facialMask.vue')
	},
	{
		path:'/socks',
		component:() => import('../page/publicize/socks.vue')
	},
	{
		path:'/official',
		component:() => import('../page/official/index.vue')
	},
	{
		path:'/h5',
		component:() => import('../page/official/index.vue')
	},
	{
		path:'/',
		name:'brand',
		component:() => import('../page/brand/brand.vue'),
		meta:{
			title:'主页'
		}
	},
	{
		path:'/officialDetail',
		name:'officialDetail',
		component:() => import('../page/officialDetail/officialDetail.vue'),
		meta:{
			title:'简驭科技'
		}
	},
	{
		path: '/joinUs',
		name: 'joinUs',
		component: () => import('../page/joinUs/joinUs.vue'),
		meta: {
			title: '加入我们'
		}
	},
	{
		path:'/health',
		name:'health',
		component:() => import('../page/health/health.vue'),
		meta:{
			title:'简驭健康'
		}
	},
	{
		path:'/life',
		name:'life',
		component:() => import('../page/life/life.vue'),
		meta:{
			title:'简驭生活'
		}
	},
	{
		path:'/laboratory',
		name:'laboratory',
		component:() => import('../page/laboratory/laboratory.vue'),
		meta:{
			title:'银基实验室'
		}
	},
	//移动端路由
	{
		path:'/phoneBrand',
		name:'phoneBrand',
		component:() => import('../page/phone/brand/brand.vue'),
		meta:{
			title:'主页'
		}
	},
	{
		path:'/phoneOfficialDetail',
		name:'phoneOfficialDetail',
		component:() => import('../page/phone/officialDetail/officialDetail.vue'),
		meta:{
			title:'简驭科技'
		}
	},
	{
		path:'/phoneLaboratory',
		name:'phoneLaboratory',
		component:() => import('../page/phone/laboratory/laboratory.vue'),
		meta:{
			title:'银基实验室'
		}
	},
	{
		path:'/phoneLife',
		name:'phoneLife',
		component:() => import('../page/phone/life/life.vue'),
		meta:{
			title:'简驭生活'
		}
	},
	{
		path:'/phoneHealth',
		name:'phoneHealth',
		component:() => import('../page/phone/health/health.vue'),
		meta:{
			title:'简驭健康'
		}
	},
	{
		path: '/phoneJoinUs',
		name: 'phoneJoinUs',
		component: () => import('../page/phone/joinUs/joinUs.vue'),
		meta: {
			title: '加入我们'
		}
	}
]
const router = createRouter({
    history: routerHistory,
	routes
})

export default router
