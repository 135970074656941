import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_h5_main_header = _resolveComponent("h5-main-header");
  const _component_router_view = _resolveComponent("router-view");
  const _component_h5_main_contact = _resolveComponent("h5-main-contact");
  const _component_h5_main_up_store = _resolveComponent("h5-main-up-store");
  const _component_h5_main_footer = _resolveComponent("h5-main-footer");
  const _component_h5_main_beian = _resolveComponent("h5-main-beian");
  return _openBlock(), _createElementBlock("div", {
    class: "main-box",
    style: _normalizeStyle({
      minWidth: _ctx.$route.path == '/h5' ? 'auto' : '100%'
    })
  }, [_createVNode(_component_h5_main_header), _createVNode(_component_router_view), $data.routePath === '/phoneJoinUs' ? (_openBlock(), _createBlock(_component_h5_main_contact, {
    key: 0
  })) : _createCommentVNode("", true), ['/phoneHealth', '/phoneLife'].includes($data.routePath) ? (_openBlock(), _createBlock(_component_h5_main_up_store, {
    key: 1
  })) : _createCommentVNode("", true), ['/phoneLaboratory', '/phoneOfficialDetail', '/phoneBrand'].includes($data.routePath) ? (_openBlock(), _createBlock(_component_h5_main_footer, {
    key: 2
  })) : _createCommentVNode("", true), _createVNode(_component_h5_main_beian, {
    routePath: $data.routePath
  }, null, 8, ["routePath"])], 4);
}