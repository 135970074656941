export default {
  name: "MainHeader",
  data() {
    return {};
  },
  methods: {
    toLink(name) {
      this.$router.push(name);
    }
  }
};