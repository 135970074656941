import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'



import './css/magic.css'

     createApp(App).use(ElementPlus).use(router).mount('#app')

// 计算并设置根元素的字体大小
function setRem() {
     const rem = document.documentElement.clientWidth / 7.5; //对标设计稿1rem = 100px
     document.documentElement.style.fontSize = rem + 'px';
}
// 初始化时调用一次
setRem();

// 监听窗口大小变化时重新计算
window.addEventListener('resize', setRem);