import Main from '../src/page/main/main';
import H5Main from "@/page/h5Main/h5Main";
export default {
  name: 'App',
  components: {
    H5Main,
    Main
  },
  data() {
    return {
      isEnd: ''
    };
  },
  mounted() {
    if (navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)) {
      this.isEnd = 'H5';
      this.$router.push('/phoneBrand');
    } else {
      this.isEnd = 'web';
      // this.$router.push('/')
    }

    console.log();
  }
};