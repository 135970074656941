import MainHeader from "@/components/MainHeader";
import MainFooter from "@/components/MainFooter";
import MainUpStore from "@/components/MainUpStore";
import MainContact from "@/components/MainContact";
import MainBeian from "@/components/MainBeian";
export default {
  name: "main",
  components: {
    MainBeian,
    MainContact,
    MainUpStore,
    MainFooter,
    MainHeader
  },
  data() {
    return {
      isTop: false,
      routePath: ''
    };
  },
  watch: {
    $route(val) {
      console.log('2323', val);
      this.routePath = val.path;
    }
  },
  mounted() {
    const vm = this;
    window.addEventListener('scroll', e => {
      vm.isTop = document.documentElement.scrollTop > 800 ? true : false;
    }, true);
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};