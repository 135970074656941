import h5MainBeian from "@/components/h5MainBeian";
import h5MainContact from "@/components/h5MainContact";
import h5MainUpStore from "@/components/h5MainUpStore";
import h5MainFooter from "@/components/h5MainFooter";
import h5MainHeader from "@/components/h5MainHeader";
export default {
  name: "h5Main",
  components: {
    h5MainBeian,
    h5MainContact,
    h5MainUpStore,
    h5MainFooter,
    h5MainHeader
  },
  data() {
    return {
      isTop: false,
      routePath: ''
    };
  },
  watch: {
    $route(val) {
      console.log('2323', val);
      this.routePath = val.path;
    }
  },
  mounted() {
    const vm = this;
    window.addEventListener('scroll', e => {
      vm.isTop = document.documentElement.scrollTop > 800 ? true : false;
    }, true);
  },
  methods: {
    toTop() {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }
};