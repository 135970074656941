export default {
  name: "h5MainBeian",
  props: {
    routePath: String
  },
  data() {
    return {
      pathName: ''
    };
  },
  watch: {
    routePath(val) {
      this.pathName = val;
    }
  }
};